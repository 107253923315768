import React, { useState } from "react";
import { Link } from "react-router-dom";
import EmplImg from "../../../assets/img/emp-img.jpg";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";
import FormGroupButton from "../../../components/GeneralComponent/FormGroupButton";
import FormGroupTable from "../../../components/GeneralComponent/FormGroupTable";

import propTypes, { bool } from "prop-types";
import { RESET_FORM_FIELDS } from "../../../redux/actionType/CrudActionTypes";
import { useEffect } from "react";
import { BASE_URL, BASE_URL_IMG } from "../../../utils/Api";
import { encryptData } from "../../../EncryptData";
import { SessionStorage } from "../../../common/SetupMasterEnum";

const CardCrudForm = (props) => {
  const {
    searchPanel,
    formPanel,
    tableColumns,
    formName,
    modalSize,
    newButton = true,
    buttonName,
    searchSubmit,
    cancelSearch,
    formSubmit,
    tableRows,
    customRows,
    onEdit,
    onDelete,
    initialSearchFields,
    initialFormFields,
    formLoad,
    submitLoad,
    checkStatus,
    onCancelForm,
    disableForceFormToggle = false,
  } = props;

  const dispatch = useDispatch();
  const { TableList, FormLoading } = useSelector(
    (state) => state.CrudFormReducer
  );

  const [toggleForm, setToggleForm] = useState(false);
  const [operationId, setOperationId] = useState(2);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    searchSubmit();
  };
  const handleSearchCancel = (e) => {
    e.preventDefault();
    cancelSearch();
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
   formSubmit(operationId, setToggleForm);
    if (!disableForceFormToggle)
      if (submitLoad == true) {
        setToggleForm(true);
      } else {
        setToggleForm(false);
      }
  };

  const handleFormCancel = () => {
    setToggleForm(false);
    setOperationId(2);
    onCancelForm();
    dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
  };

  const onEditRow = (index) => {
    let obj = TableList[index];
    onEdit(obj);
    setOperationId(3);
    setToggleForm(true);
  };

  const onDeleteRow = (index) => {
    let obj = TableList[index];
    onDelete(obj);
  };
  const getEmployeeId = (id) => {
    encryptData(id, "EmplGetId", SessionStorage);
    // localStorage.setItem("EmplGetId", id);
  };
  return (
    <Container fluid>
      <Card className="mt-3">
        <CardTitle>Search {formName}</CardTitle>
        <CardBody>
          <form onSubmit={handleSearchSubmit}>
            {searchPanel && (
              <Row>
                {searchPanel}
                <Col lg="12" md="12" xs="12" className="text-right">
                  <Button color="primary" className="btn" type="submit">
                    Search
                  </Button>
                  <Button
                    className="btn"
                    color="default"
                    onClick={handleSearchCancel}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            )}
          </form>
        </CardBody>
      </Card>
      <Card>
        <CardTitle>
          <Row>
            <Col lg="6" md="3" xs="12">
              {formName} List
            </Col>
            <Col lg="6" md="3" xs="12" className="text-right">
              {newButton && (
                <FormGroupButton
                  title={buttonName}
                  onClick={() => setToggleForm(true)}
                  id="add-btn"
                  //   isOpen={tooltip}
                  //   toggle={() => setToolTip(!tooltip)}
                  showToolTip={false}
                  toolTipTitle="Add"
                  showIcon={true}
                ></FormGroupButton>
              )}
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
          {formLoad ? (
            <div className="form-load">
              <Spinner color="light" size="md" />
            </div>
          ) : null}
          <Row>
            {
              tableRows &&
                tableRows.map((data, key) => (
                  <Col lg="4" md="4" sm="6" key={key}>
                    <Card className="employee-card">
                      <CardBody className="d-flex">
                        <div className="pic">
                          <Link
                            onClick={() => getEmployeeId(data.EmployeeId)}
                            to={"/pages/hrms/profile/1"}
                          >
                            <img
                              src={data.ImagePath_new}
                              className="img-fluid"
                              alt="Employee"
                            />
                          </Link>
                        </div>
                        <div className="employee-detail">
                          <h6 className="mb-0">
                            <Link
                              onClick={() => getEmployeeId(data.EmployeeId)}
                              to={"/pages/hrms/profile/1"}
                            >
                              {data.EmployeeName}
                            </Link>
                          </h6>
                          <p className="font-size-normal mb-1">
                            {data.Designation} <br />
                            Emp # {data.EmployeeCode}
                          </p>
                          {/* <p className="font-size-normal mb-1">
                            {data.Designation} <br />
                            Emp # {data.EmployeeId}
                          </p> */}
                          <p className="direction font-size-normal mb-0">
                            <span className="badge badge-info">Employee</span>
                          </p>
                          <p className="font-size-small mb-0">
                            {data.SoftwreLoginId}
                          </p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))
              // <FormGroupTable
              //   columns={tableColumns}
              //   rows={tableRows}
              //   onEdit={onEditRow}
              //   onDelete={onDeleteRow}
              // />
            }
            {customRows && (
              <FormGroupTable
                columns={tableColumns}
                rows={customRows}
                onEdit={onEditRow}
                onDelete={onDeleteRow}
              />
            )}
            {/* </Col> */}
          </Row>
        </CardBody>
      </Card>

      <Modal
        isOpen={toggleForm}
        centered
        size={modalSize}
        // toggle={toggle}
        modalTransition={{ timeout: 10 }}
        backdrop="static"
      >
        <ModalHeader
        // toggle={toggle}
        >
          Add/Edit {formName}
        </ModalHeader>
        <ModalBody>
          <form  onSubmit={handleFormSubmit}>
            {formPanel && (
              <Row>
                {formPanel}
                <div
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  className="mt-2"
                >
                  <Button type="submit" color="primary">
                    {submitLoad ? (
                      <div className="form-load">
                        <Spinner color="light" size="sm" />
                      </div>
                    ) : null}
                    Save
                  </Button>
                  <Button color="default" onClick={handleFormCancel}>
                    Cancel
                  </Button>
                </div>
              </Row>
            )}
          </form>
        </ModalBody>
      </Modal>
    </Container>
  );
};

CardCrudForm.propTypes = {
  searchPanel: propTypes.element,
  formPanel: propTypes.element,
  tableColumns: propTypes.array,
  formName: propTypes.string,
  modalSize: propTypes.string,
  newButton: propTypes.bool,
  buttonName: propTypes.string,
  searchSubmit: propTypes.func,
  formSubmit: propTypes.func,
  tableRows: propTypes.array,
  customRows: propTypes.array,
  onEdit: propTypes.func,
  onDelete: propTypes.func,
  initialSearchFields: propTypes.object,
  initialFormFields: propTypes.object,
  checkStatus: propTypes.string,
  onCancelForm: propTypes.func,
  disableForceFormToggle: bool,
};

export default CardCrudForm;
